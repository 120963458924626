const getWebsite = () => {
  return window.$website
}

export const getCurrentEnv = () => {
  return window.$env
}

function firstLetterInMayus(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const nameComponent = () => {
  const web = firstLetterInMayus(getWebsite())
  const env = firstLetterInMayus(getCurrentEnv())
  const component = `${web}${env}`
  return component
}

export default getWebsite
