import rsc from "@Config/resources"

export const values = [
  {
    id: "orders",
    filterType: ["range"],
    name: "orders",
    resourceId: rsc.ReportOrder,
  },
  {
    id: "item_order",
    filterType: ["range"],
    name: "item_order",
    resourceId: rsc.ReportOrderDetail,
  },
  {
    id: "availability",
    filterType: ["channel"],
    name: "availability",
    resourceId: rsc.Disponibility,
  },
  {
    id: "orders_in_reserve",
    filterType: ["range"],
    name: "orders_in_reserve",
    resourceId: rsc.OrderReserve,
  },
  {
    id: "output_of_service_request",
    filterType: ["range"],
    name: "output_of_service_request",
    resourceId: rsc.RequestMs,
  },
  {
    id: "transactions",
    filterType: ["range"],
    name: "transactions",
    resourceId: rsc.Transactions,
  },
  {
    id: "order_operation",
    filterType: ["range"],
    name: "order_operation",
    resourceId: rsc.ReportOperation,
  },
  {
    id: "items",
    filterType: [],
    name: "items",
    resourceId: rsc.ReportItems,
  },
  // {
  //   id: "outputs_of_service_retry",
  //   filterType : ['range'],
  //   name: "outputs_of_service_retry",
  //   resourceId: "",
  // },
  // {
  //   id: "output_of_shippingroup_retry",
  //   filterType : ['range'],
  //   name: "output_of_shippingroup_retry",
  //   resourceId: "",
  // }
]

// Casaideas
// "orders",
// "item_order",
// "availability",
// "output_of_service_request",
// "transactions",
// "items"

// Adretail
// "order_operation",
// "orders",
// "item_order",
// "availability",
// "orders_in_reserve",
// "output_of_service_request",
// "transactions",
// "items",

// Logytech
// "orders",
// "items"

// Sandbox
// Sandbox it's same Casaideas