import { useEffect, useState } from "react"
import _translate from "../translates"

const useFiltersInformation = (
  filtersProps,
  generateDataProps,
  valProps,
  useDisable,
  useDisableById = false
) => {
  // State to manage if a filter is not empty
  const [disable, setDisable] = useState(true)
  // State to disabled all options except Id
  const [disableExceptId, setDisableExceptId] = useState(false)

  // State to manage the values selected in the filters and the default values
  const [valuesGenerate, setvaluesGenerate] = useState([...filtersProps])
  // State in charge to render the table
  const [generateData, setGenerateData] = useState(generateDataProps)
  // State to keep track what options are selected
  const [val, setVal] = useState([...valProps])

  /*
  If useDisable is true this effect will track if valuesGenerate have empty values
  If useDisableById is true this effect will track if valuesGenerate 
  have a element with the name of Id and change DisableExceptId to true (All options are available) or 
  false (All other options Except Id are disable)
  */
  useEffect(() => {
    if (useDisable === true) {
      if (checkEmpty() === true || val.length === 0) {
        setDisable(true)
      } else {
        setDisable(false)
      }
    }

    if (useDisableById === true) {
      const indVal = valuesGenerate.findIndex(({ name }) => name === "Id")
      if (val.includes("Id") && valuesGenerate[indVal].value === "") {
        setDisableExceptId(false)
      }

      if (val.includes("Id") && valuesGenerate[indVal].value !== "") {
        setDisableExceptId(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, valuesGenerate])

  /*
   * Handle the value of ValuesGenerate
   * @param {String} key - Equivalent to "name" in  in valuesGenerate (filtersInfo.js)
   * @param {Any} value - Current Value to replace "value" in valuesGenerate (filtersInfo.js)
   * @param {Integer} index - If the element is filterType = "selectBox" this param is mandatory, is the index of the value to change
   * @return {void} Nothing
   */
  const handleValue = (key, value, index) => {
    const ind = valuesGenerate.findIndex((object) => object.name === key)
    const found = { ...valuesGenerate[ind] }

    switch (found.filterType) {
      case "selectBox":
        const tempArray = [...found.value]
        tempArray[index] = value
        const newValueSelect = { ...found, value: [...tempArray] }
        const tempSelect = [...valuesGenerate]
        tempSelect[ind] = newValueSelect
        setvaluesGenerate([...tempSelect])
        break
      case "multiDate":
        const newDates = value.map((val) => {
          if (val === null) {
            return null
          } else {
            return new Date(val)
          }
        })
        const newValueDates = { ...found, value: [...newDates] }
        const tempDates = [...valuesGenerate]
        tempDates[ind] = { ...newValueDates }
        setvaluesGenerate([...tempDates])
        break
      case "singleValue":
        const newValue = { ...found, value: value }
        const temp = [...valuesGenerate]
        temp[ind] = { ...newValue }
        setvaluesGenerate([...temp])
        break
      case "multipleValues":
        const newValueMultiples = { ...found, value: [...value] }
        const tempMultiples = [...valuesGenerate]
        tempMultiples[ind] = { ...newValueMultiples }
        setvaluesGenerate([...tempMultiples])
        break
      case "singleValueNeedTranslation":
        const newValueTranslate = { ...found, value: value }
        const tempTranslate = [...valuesGenerate]
        tempTranslate[ind] = { ...newValueTranslate }
        setvaluesGenerate([...tempTranslate])
        break
      case "multipleValueNeedTranslation":
        const newMultipleValueTranslate = { ...found, value: value }
        const tempTranslateMultiple = [...valuesGenerate]
        tempTranslateMultiple[ind] = { ...newMultipleValueTranslate }
        setvaluesGenerate([...tempTranslateMultiple])
        break
      case "objectTwoOptions":
        const tempObject = [...valuesGenerate]
        if (value.type === "Source" || value.type === "Courier") {
          const newValueSource = {
            ...found,
            type: value.type,
            value: value.value,
          }
          tempObject[ind] = { ...newValueSource }
          setvaluesGenerate([...tempObject])
        }
        if (value.type === "Location") {
          const newValueLocation = {
            ...found,
            type: value.type,
            value: [...value.value],
          }
          tempObject[ind] = { ...newValueLocation }
          setvaluesGenerate([...tempObject])
        }
        break
      default:
        setvaluesGenerate([...valuesGenerate])
        break
    }
  }

  /*
   * Reset the valuesGenerate element searching by option
   * @param {String} option - Equivalent to "name" in  in valuesGenerate (filtersInfo.js)
   * @param {function} setWhoOpen - function to reset WhoOpen
   * @return {void} Nothing
   */
  const handleDelete = (option, setWhoOpen) => {
    const ind = valuesGenerate.findIndex((value) => value.name === option)
    const found = { ...valuesGenerate[ind] }
    let tempArray
    let newValue
    let temp

    // reset Disable if deleteValue is Id and useDisableExceptId is

    if (useDisableById === true && found.name === "Id") {
      setDisableExceptId(false)
    }

    switch (found.filterType) {
      case "selectBox":
        tempArray = found.value.map(() => {
          return false
        })
        newValue = { ...found, value: [...tempArray] }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      case "multiDate":
        tempArray = found.value.map(() => {
          return null
        })
        newValue = { ...found, value: [...tempArray] }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      case "singleValue":
        newValue = { ...found, value: "" }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      case "multipleValues":
        newValue = { ...found, value: [""] }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      case "singleValueNeedTranslation":
        newValue = { ...found, value: "" }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      case "multipleValueNeedTranslation":
        tempArray = found.value.map(() => {
          return ""
        })
        newValue = { ...found, value: tempArray }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      case "objectTwoOptions":
        newValue = { ...found, type: "", value: "" }
        temp = [...valuesGenerate]
        temp[ind] = newValue
        setvaluesGenerate([...temp])
        break
      default:
        break
    }
    setWhoOpen("")
    setVal([...val.filter((entry) => entry !== option)])
  }

  /*
   * Build a String to be displayed in the AutoComplete Component on the Filters
   * @param {String} who - Equivalent to "name" in  in valuesGenerate (filtersInfo.js)
   * @return {String} Element display in chips of AutoComplete Filter
   */
  const displayInfoChip = (who) => {
    const ind = valuesGenerate.findIndex((value) => value.name === who)
    const found = { ...valuesGenerate[ind] }

    switch (found.filterType) {
      case "selectBox":
        if (found.value.length > 0) {
          let concant = who
          let count = 0
          found.value.map((val, ind) => {
            if (val === true) {
              if (count === 0) {
                concant = concant + ": " + found.options[ind]
              } else {
                concant = concant + "," + found.options[ind]
              }
              count = count + 1
            }
            return false
          })
          return concant
        } else {
          return who
        }
      case "singleValue":
        if (found.value !== "") {
          const concant = who + ": " + found.value
          return concant
        } else {
          return who
        }
      case "multipleValues":
        if (found.value.includes("") === false) {
          const concant = who + ": " + found.value.join(",")
          return concant
        } else {
          return who
        }
      case "multiDate":
        let concant = who

        if (found.value[0] !== null || found.value[1] !== null) {
          if (found.value[0] !== null) {
            concant = concant + ": " + convertDateDisplay(found.value[0])
          }
          if (found.value[1] !== null) {
            concant = concant + " - " + convertDateDisplay(found.value[1])
          }
        }
        return concant

      case "objectTwoOptions":
        if (found.type !== "") {
          if (found.type === "Source" || found.type === "Courier") {
            const concant = who + ": " + found.value
            return concant
          }

          if (found.type === "Location") {
            const tempTranslation = found.value
              .filter((val) => val !== "")
              .map((val) => {
                return _translate(val)
              })
            const concant = who + ": " + tempTranslation.join(",")
            return concant
          }
        } else {
          return who
        }
        /* falls through */
      case "singleValueNeedTranslation":
        if (found.value !== "") {
          const concant = who + ": " + _translate(found.value)
          return concant
        } else {
          return who
        }
        /* falls through */
      case "multipleValueNeedTranslation":
        if (found.value.includes("") === false) {
          const tempTranslate = found.value.map((val) => {
            return _translate(val)
          })

          const concant = who + ": " + tempTranslate.join(",")
          return concant
        } else {
          return who
        }

      default:
        return "not defined"
    }
  }

  /*
   * Build a String using the format dd/mm/yyyy of a date
   * @param {Date} date - Date object
   * @return {String}  String with a date in format dd/mm/yyyy
   */
  const convertDateDisplay = (date) => {
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    )
  }

  /*
   * Reset all filter values
   * @param {Array}  values -
   * @return {void} Nothing
   */
  const ResetAll = (values) => {
    setDisableExceptId(false)
    setVal([])
    setvaluesGenerate([...values])
  }

  /*
   * Check all elements in valuesGenerate if the value is on default
   * @return {Boolean} if the returned value is true any value of valuesGenerate is empty , if return false all the values are not empty
   */
  const checkEmpty = () => {
    let checker = []
    val.forEach((val) => {
      let ind = valuesGenerate.findIndex((value) => value.name === val)
      let found = valuesGenerate[ind]
      let flag = true
      switch (found.filterType) {
        case "selectBox":
          found.value.forEach((temp) => {
            if (temp === true) {
              flag = false
            }
          })
          break

        case "multiDate":
          found.value.forEach((temp) => {
            if (temp !== null) {
              flag = false
            }
          })
          break

        case "singleValue":
          if (found.value !== "") {
            flag = false
          }
          break
        case "multipleValues":
          if (!found.value.includes("")) {
            flag = false
          }
          break

        case "singleValueNeedTranslation":
          if (found.value !== "") {
            flag = false
          }
          break
        default:
          break
      }
      checker.push(flag)
    })

    if (checker.includes(true)) {
      return true
    } else {
      return false
    }
  }

  return {
    disable,
    valuesGenerate,
    generateData,
    val,
    disableExceptId,
    setVal,
    setGenerateData,
    handleValue,
    handleDelete,
    displayInfoChip,
    ResetAll,
  }
}

export default useFiltersInformation
