import React, { Suspense } from "react"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormItems").render()

const CreateItem = () => {
  return (
    <Suspense fallback>
      <Component />
    </Suspense>
  )
}

export default CreateItem
