import React from "react"
import AppRouter from "@Routes/AppRouter"
import { ClientContext } from "@Context/context"
import AuthProvider from "@Context/context/AuthContext"
import TableProvider from "@Context/context/TableContext"
import "@Webcomponents/OmnixCustomData/index"
import "@Translations"

const AppContainer = () => {
  console.log(102)
  return (
    <ClientContext>
      <AuthProvider>
        <TableProvider>
          <AppRouter />
        </TableProvider>
      </AuthProvider>
    </ClientContext>
  )
}

export default AppContainer
