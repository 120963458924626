import React, { useEffect, useContext, useState, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { GlobalContext } from "@Context/context/GlobalState"
import { getOrderDetails } from "@Services/orderService"
import { AuthContext } from "@Context/context/AuthContext"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import Swal from "sweetalert2"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormOrdersReturn").render()

const ReturnOrderScreen = () => {
  const { orderId } = useParams()
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const [info, setInfo] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.SgCrudCreationOrderRt],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.SgCrudCreationOrderRt]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("withdraw-order"),
      routes: [
        { name: t("orders"), route: "/orders" },
        { name: t("withdraw-order"), route: `/orders/return/${orderId}` },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    loadDataOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadDataOrder = async () => {
    try {
      let response = await getOrderDetails(user.user.token, orderId)
      setInfo(response.data.message)
      if (response?.data?.message?.currentStatus?.tag === "CLOSED") {
        Swal.fire({
          title: t("oops"),
          text: t(
            "the-order-you-are-trying-to-withdraw-has-already-been-closed"
          ),
          confirmButtonColor: "#FD0049",
        })
      }
      if (response?.data?.message?.currentStatus?.tag !== "DELIVERED") {
        history.push("/orders")
      }
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component load={load} currentOrder={info} />
    </Suspense>
  )
}

export default ReturnOrderScreen
