import TableRow from "@material-ui/core/TableRow"
import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from "@material-ui/core/TablePagination"
import Table from "@material-ui/core/Table"

import React from "react"

function FooterTable({
  rowsPerPage,
  page,
  maxRecord,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <Table>
      <TableFooter>
        <TableRow style={{ backgroundColor: "transparent" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={maxRecord}
            onChangePage={(event, value) => handleChangePage(value)}
            onChangeRowsPerPage={(event, value) =>
              handleChangeRowsPerPage(event)
            }
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default FooterTable
